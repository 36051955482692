<template>
  <article class="h-inherit pt-12 md:h-auto md:pt-0 md:max-w-1200 md:mx-auto" v-if="productDetail">
    <div class="bg-gray-100 md:bg-white min-h-full h-screen md:h-auto flex flex-col justify-between">
      <section class="hidden md:block my-6">
        <p class="text-lg m-0">
          <router-link :to="{ name: 'products' }" class="flex items-center"> <a-icon type="arrow-left" class="mr-2" />回上一頁 </router-link>
        </p>
      </section>

      <!--PC & Mobile 商品-->
      <section class="bg-white pb-3 relative flex flex-col shadow-none rounded-tl-3xl rounded-tr-3xl rounded-b-none md:grid md:grid-cols-2 md:gap-8 mt-3 md:mt-0">
        <!--標籤-->
        <article class="c-label-area" v-if="productDetail.spans">
          <label v-for="(n, nIndex) in 3" :key="n" class="block mb-1">
            <template v-if="productDetail.spans[nIndex]">
              <div class="ribon-img start relative" :class="[nIndex % 2 === 0 ? 'yellow' : 'pink']">
                <span class="ribon-text">{{ productDetail.spans[nIndex].name }}</span>
                <div class="ribon-img end absolute" :class="[nIndex % 2 === 0 ? 'yellow' : 'pink']"></div>
              </div>
            </template>
          </label>
        </article>

        <!--倒數 & 圖片-->
        <article>
          <div class="relative">
            <div class="c-product-image-container md:mt-0 mb:mb-0 rounded-tl-3xl rounded-tr-3xl md:rounded-none w-full h-auto md:my-0">
              <img class="c-product-image rounded-tl-3xl rounded-tr-3xl md:rounded-none" :src="productDetail.image" :onerror="imgNotFound" />
            </div>

            <div v-if="COUNTDOWN_SEC > 0" class="countdown">
              <div class="flex items-center">
                <img class="mr-2" src="../../assets/img/products/icon_time.svg" />
                限時
              </div>
              <countdown :left-time="COUNTDOWN_SEC">
                <div class="flex items-center" slot="process" slot-scope="{ timeObj }">
                  <span class="text-lg mr-2">{{ formatCountDown(timeObj.org.d) }}日</span>
                  <span class="countdown__timer">
                    <div class="timer__round">{{ formatCountDown(timeObj.org.h) }}</div>
                    :
                    <div class="timer__round">{{ formatCountDown(timeObj.org.m) }}</div>
                    :
                    <div class="timer__round">{{ formatCountDown(timeObj.org.s) }}</div>
                  </span>
                </div>
                <div class="flex items-center" slot="finish">倒數已結束</div>
              </countdown>
            </div>
          </div>
        </article>

        <div class="p-4 md:p-0">
          <h2 class="c-product-title text-2xl mb-2 mx-0 md:mb-6">{{ productDetail.name }}</h2>

          <!-- 總庫存 -->
          <article class="mb-2 md:mb-6 flex flex-row justify-between items-center">
            <span class="bg-gray-50 rounded-sm text-gray-400 py-1 px-2 md:text-lg mr-2"> 總庫存 {{ productDetail.stock }} </span>
            <h6 class="flex justify-end rounded-sm text-sm md:text-lg text-right">
              <div class="mr-2 text-brown">
                原價
              </div>
              <div class="mr-2 text-pink-salmon font-bold">
                ${{ productDetail.price }}
              </div>
              <div class="text-gray-400">
                <!-- <small>/入</small> -->
              </div>
            </h6>
          </article>

          <!--規格剩餘量-->
          <article class="mb-2 md:mb-6 flex flex-wrap">
            <span v-for="spec in productDetail.specs" :key="spec.id" class="bg-pink-salmon-100 rounded-sm text-pink-salmon py-1 px-2 md:text-lg mr-2 mb-1"> {{ spec.name }} <span class="text-gray-400"> 庫存 {{ spec.stock }} </span> </span>
          </article>

          <!--價-->
          <article class="text-brown">
            <ul class="mb-0 pb-0">
              <li v-for="(item, idx) in productDetail.packs" :key="idx" class="p-2 border-t border-gray-200 border-blue-100" :class="{ 'border-b-0': idx == productDetail.packs.length - 1 }">
                <div class="flex justify-between items-start mb-2 md:mb-4">
                  <span class="md:text-xl leading-8 max-w-50">{{ item.name }}</span>
                  <div class="flex flex-col items-end min-w-1/2">
                    <!-- <span class="flex font-bold text-pink-salmon items-center">
                      <h4 class="text-3xl text-pink-salmon">{{ item.price.grouped }}<span class="text-sm text-gray-400"> /入</span></h4>
                    </span> -->
                    <!-- <h6 class="flex justify-end rounded-sm text-sm md:text-lg text-right">
                      <div class="mr-0 text-brown">
                        <p class="m-0 pr-10 pl-2 text-left">團組批發</p>
                        <div class="h-2"></div>
                        <p class="m-0 bg-gray-100 text-gray-450 pr-10 pl-2 text-left">建議販售價</p> 
                        <div class="h-1"></div>
                        <p class="m-0 bg-gray-100 text-gray-450 pr-10 pl-2 text-left">獲利差</p>
                      </div>
                      <div class="mr-0 text-pink-salmon font-bold">
                        <p class="m-0 text-pink-salmon">
                          <span class="price-text align-bottom">{{ item.price.grouped }}</span>
                        </p>
                        <div class="h-2"></div>
                        <p class="m-0 bg-gray-100 pr-1">${{ item.price.suggested }}</p> 
                        <div class="h-1"></div>
                        <p class="m-0 bg-gray-100 pr-1">${{ item.price.profit }}</p> 
                      </div>
                      <div class="text-gray-400">
                        <p class="bg-gray-100 m-0 pr-2">/入</p>
                        <div class="h-2"></div>
                        <p class="bg-gray-100 m-0 pr-2">/入</p>
                        <div class="h-1"></div>
                        <p class="bg-gray-100 m-0 pr-2">/入</p>
                      </div>
                    </h6> -->
                    <div class="flex justify-end rounded-sm text-sm md:text-lg">
                      <div>
                        <div class="mb-2 flex justify-between items-end font-bold">
                          <span class="pr-10 pl-3 text-left">團主批發</span>
                          <div>
                            <span class="price-color">$<span class="price-text">{{ item.price.grouped }}</span></span>
                            <span class="mb-0 pr-3">/{{ item.unit == '' ? '入' : item.unit }}</span>
                          </div>
                        </div>
                        <div class="mb-1 bg-gray-100 justify-between flex items-end">
                          <span class="mb-0 text-gray-500 pr-10 pl-3 text-left">建議販售價</span>
                          <div>
                            <span class="mb-0 text-pink-salmon pr-1">${{ item.price.suggested }}</span>
                            <span class="mb-0 text-gray-500 pr-3">/{{ item.unit == '' ? '入' : item.unit }}</span>
                          </div>
                        </div>
                        <div class="mb-1 bg-gray-100 justify-between flex items-end">
                          <span class="mb-0 text-gray-500 pr-10 pl-3 text-left">獲利差</span>
                          <div>
                            <span class="mb-0 text-pink-salmon pr-1">${{ item.price.profit }}</span>
                            <span class="mb-0 text-gray-500 pr-3">/{{ item.unit == '' ? '入' : item.unit }}</span>
                          </div>
                        </div>
                        <!-- <div class="mb-1 bg-gray-100 justify-between flex items-end">
                          <span class="mb-0 text-gray-500 pr-10 pl-3 text-left">回饋金</span>
                          <div>
                            <span class="mb-0 text-pink-salmon pr-1">${{ item.price.benefit }}</span>
                            <span class="mb-0 text-gray-500 pr-3">/{{ item.unit == '' ? '入' : item.unit }}</span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>

                <!--TODO 等獲利差api-->
              </li>
            </ul>
          </article>

          <article class="p-3 mb-4 bg-gray-100 text-gray-500">
            <h4 class="mb-1 text-gray-500">回饋金說明：</h4>
            <section class="px-4">
              <ul class="product-ul text-sm list-disc list-outside mb-0"> 
                <li>回饋金將於商品配達後 10 日加入計算</li>
                <li>每季結算日為 03/15、06/15、09/15、12/15，發放日期為結算日之次月25日(01/25、04/25、07/25、10/25)，如遇假日則順延至下個工作日</li>
                <li>回饋金若遇小數點，將取整數(四捨五入)計算，結算日後歸零</li>
                <li>回饋金將以 COCO 幣形式發放，可用於揪美美消費時折抵使用</li>
              </ul>
            </section>
          </article>

          <!-- PC 按鈕 -->
          <div class="btn-bottom-groups ml-20 hidden md:flex">
            <!-- <button class="btn-forms w-full" @click="handleBack">分享連結</button> -->
            <div class="w-3/4">
              <button class="btn-forms w-full" v-clipboard:copy="cleanText" v-clipboard:success="onCopy" v-clipboard:error="onError" @click="handleGroupOrder">發起團購</button>
            </div>
          </div>
        </div>
      </section>

      <!-- PC 商品資訊-->
      <section class="bg-white mb-5 p-4 hidden md:block">
        <header class="items-center flex mb-4">
          <img class="h-5 w-5 mr-2" src="../../assets/img/icon_shoppingbag.svg" />
          <h4 class="font-bold text-lg">商品資訊</h4>
        </header>
        <div class="response-style" v-html="productDetail.desc" ref="resText"></div>
      </section>

      <!--Mobile 卡片之間空間-->
      <section class="h-10 bg-gray-100 text-gray-100 text-opacity-0 block md:hidden">-</section>

      <!--Mobile 商品資訊-->
      <section class="bg-white p-4 md:hidden">
        <header class="items-center flex mb-4">
          <img class="h-5 w-5 mr-2" src="../../assets/img/icon_shoppingbag.svg" />
          <h4 class="font-bold text-lg">商品資訊</h4>
        </header>
        <div class="response-style" v-html="productDetail.desc" ref="resText"></div>
      </section>

      <!--Mobile 卡片之間空間-->
      <section class="h-10 bg-gray-100 text-gray-100 text-opacity-0 block md:hidden">-</section>

      <!--PC & Mobile Q&A-->
      <section class="bg-white p-4">
        <header class="items-center flex mb-4">
          <img class="h-5 w-5 mr-2" src="../../assets/img/icon_question.svg" />
          <h4 class="font-bold text-lg">Q&A</h4>
        </header>
        <hr v-if="productQuestions" class="mb-8" />
        <article class="flex mb-10" v-for="(qa, idx) in productQuestions" :key="idx">
          <div class="mr-2"><img class="mt-1" src="../../assets/img/icon_q.svg" /></div>
          <div class="w-full">
            <p v-if="qa.time" class="text-lg text-brown opacity-50">
              <!--問-->
              {{ qa.time }}
            </p>
            <p class="text-lg mb-2">{{ qa.content }}</p>

            <!--問答會有多筆回復-->
            <template v-if="qa.replies && qa.replies.length > 0">
              <article v-for="(qaReplay, replayIndex) in qa.replies" class="flex bg-pink-salmon-100 p-4 rounded-lg mb-2" :key="replayIndex">
                <div class="mr-2"><img class="mt-1 h-5 w-5" src="../../assets/img/icon_a.svg" /></div>
                <div class="w-full">
                  <div class="text-lg text-pink-salmon opacity-60">
                    {{qaReplay.time}}
                  </div>
                  <!--回-->
                  <div class="text-lg">{{qaReplay.content}}</div>
                </div>
              </article>
            </template>
          </div>
        </article>

        <!--留下問題-->
        <section class="relative">
          <a-textarea class="textarea-basic" v-model="form.content" placeholder="留下問題" :auto-size="{ minRows: 3, maxRows: 5 }" allow-clear />
          <button @click="handlePostQA()" class="btn-circle absolute right-2 bottom-2"><img src="../../assets/img/icon_send.svg" /></button>
        </section>
        <div class="h-24"></div>
      </section>

      <!--下方按鈕-->
      <section class="fixed w-full p-3 bottom-0 bg-white custom-shadow-top md:hidden">
        <div class="btn-bottom-groups">
          <button class="btn-forms btn-light w-1/2" @click="handleBack">回清單頁</button>
          <div class="w-1/2">
            <button class="btn-forms w-full" v-clipboard:copy="cleanText" v-clipboard:success="onCopy" v-clipboard:error="onError" @click="handleGroupOrder">發起團購</button>
          </div>
        </div>
      </section>

      <!--複製不帶價格文案-->
      <a-modal centered :closable="false" v-model="modals.copyText.isShow">
        <template slot="title"></template>
        <img class="custom-modal-img" src="@/assets/img/img_copy.png" />
        <!-- <p class="font-bold text-center">已複製不帶價格之文案</p>
        <p class="font-bold text-center">商品規格及數量請至購物車更新</p> -->
        <p class="font-bold text-center">1.已複製產品介紹文字，可直接在您的販售群組貼上。</p>
        <p class="font-bold text-center">2.若要直接訂購，請至購物車直接修改數量並結帳。</p>
        <template slot="footer">
          <a-button class="text-center w-full border-0" @click="setModalHide('copyText')">
            <span class="text-pink-salmon">關 閉</span>
          </a-button>
        </template>
      </a-modal>
    </div>
  </article>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { LogEnums } from '@/utils/enums'
import { browseProductDetail, addToCartEvent } from '@/utils/ga.js'

// import message from 'ant-design-vue/es/message'

//商品剩餘時間
// const STOPPED_AT = '2022-01-01 23:20:03'

export default {
  name: 'productDetail',
  data() {
    return {
      PRODUCT_ID: '',
      form: {
        content: ''
      },
      formAddToCart: '',
      imgNotFound: 'this.src="' + require('../../assets/img/status/not_found_img.jpg') + '"',
      modals: {
        copyText: {
          isShow: false
        }
      },
      STOPPED_AT: '',
      COUNTDOWN_SEC: '',
      cleanText: '',
      flag: -1 //隱形賣場
    }
  },
  computed: {
    ...mapGetters(['productDetail', 'productQuestions', 'loading']),
    formatStoppedDate() {
      return 1
    }
  },
  methods: {
    ...mapActions(['getDataProductDetail', 'question', 'postProductQuestions', 'addCartData', 'postLog']),
    formatCountDown(unit) {
      //保持2位數
      unit = Math.floor(unit)
      if (unit < 10) {
        unit = '0' + String(unit)
      }
      return unit
    },
    setModalHide(item) {
      this.modals[item].isShow = false
    },
    setModalShow(item) {
      this.modals[item].isShow = true
    },
    handleBack() {
      this.$router.replace('/products/')
    },
    async initFormData() {
      let data = {}
      await Object.assign(data, {
        product: {
          id: this.productDetail.id,
          name: this.productDetail.name,
          image: this.productDetail.image
        },
        price: {
          total: 0,
          average: 0
        },
        packs: this.productDetail.packs,
        specs: this.productDetail.specs,
        flag: this.flag
      })

      data.specs.forEach((element) => {
        element.quantity = 0
      })

      data.packs.forEach((element) => {
        //預設0
        element.stock = 10
        element.status = 1
        element.spec = 0
        element.quantity = 0
        element.specs = data.specs
      })

      this.formAddToCart = data
    },
    removeHtml(value) {
      let div = document.createElement('div')
      div.innerHTML = value
      let text = div.textContent
      this.cleanText = text
    },
    onCopy: function () {
      console.log('複製成功')
    },
    onError: function () {
      console.log('複製失敗')
    },
    async handleGroupOrder() {
      //* api 發起團購
      await this.initFormData()
      let params = new FormData()
      await params.append('data', JSON.stringify(this.formAddToCart))
      await this.addCartData(params)
      this.postLog({
        code: LogEnums.ADD_CART
      })
      this.setModalShow('copyText')

      const item = this.handleaddToCartEventData()
      this.$gtm.trackEvent(addToCartEvent(item))
    },
    async handlePostQA() {
      let formData = new FormData()
      let id = this.PRODUCT_ID
      formData.append('content', this.form.content)
      const apiPass = await this.postProductQuestions({ id, formData }) //問問題
      if (apiPass == 0) this.form.content = ''
      this.getDataProductDetail({ id: this.PRODUCT_ID, flag: this.flag }) //重讀資料
    },
    handleGaBorseProductDetailData() {
      return [{
        name: this.productDetail.name,
        id: this.productDetail.id,
        price: this.productDetail.price
      }]
    },
    handleaddToCartEventData() {
      return [{
        name: this.productDetail.name,
        id: this.productDetail.id,
        price: this.productDetail.packs[0].price.grouped,
        quantity: 1
      }]
    }
  },
  created() {
    //如果在products/:id 刷新, 這裡會取id的值
    let array = String(this.$route.path).split('/')
    this.PRODUCT_ID = array[2]

    if (this.$route.query.flag != undefined) {
      this.flag = this.$route.query.flag
    }
  },
  watch: {
    'productDetail.desc': {
      handler: function (newVal) {
        this.removeHtml(newVal)
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.PRODUCT_ID = this.$route.params.id
    }
    if (this.$route.query.flag != undefined) {
      this.flag = this.$route.query.flag
    }
    
    await this.getDataProductDetail({ id: this.PRODUCT_ID, flag: this.flag }) //打api讀取商品id
    this.STOPPED_AT = this.productDetail.timer
    this.COUNTDOWN_SEC = moment(this.STOPPED_AT).valueOf() - moment().valueOf()
    this.postLog({
      code: LogEnums.INTO_PRODUCT_DETAIL
    })

    const item = this.handleGaBorseProductDetailData()
    this.$gtm.trackEvent(browseProductDetail(item))
  }
}
</script>

<style lang="postcss" scoped>
/*後台傳回帶有html的資料*/
.response-style p,
.response-style li,
.response-style br {
  margin-bottom: 1rem;
}
.response-style img {
  width: 100%;
  max-width: 100% !important;
}

/*商品圖*/
.c-product-image-container {
  position: relative;
  width: 100%;
}

.c-product-image-container:after {
  content: '';
  display: block;
  padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}

.c-product-image {
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%; /* This if for the object-fit */
  height: 100%; /* This if for the object-fit */
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

/*商品標題*/
.c-product-title {
  color: #513e41;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

/*標籤靠左*/
.c-label-area {
  position: absolute;
  top: 0.5rem;
  left: 0;
  color: unset;
  z-index: 9;
}

/*倒數計時*/
.countdown {
  @apply absolute;
  @apply bg-orange;
  @apply bottom-0;
  @apply flex;
  @apply items-center;
  @apply justify-between;
  @apply px-4;
  @apply py-1;
  @apply text-white;
  @apply w-full;
}
.countdown__timer {
  @apply flex;
  @apply items-center;
}
.timer__round {
  @apply bg-white;
  @apply font-bold;
  @apply inline-block;
  @apply p-1;
  @apply rounded-md;
  @apply rounded-xl;
  @apply text-center;
  @apply text-orange;
}

/*商品剩餘量*/
.c-lastItem-area {
  @apply bg-pink-salmon-100;
  @apply rounded-b-md;
  @apply w-auto;
}

/* .dashboard-ul li::before{
  content: '♦ ';
} */

.product-ul li {
  margin-bottom: 0;
}
.product-ul li::marker {
  content: '♦ ';
}

.price-text {
  font-size: 1.875rem;
}

.price-color {
  color: #FF2358
}

</style>
